.container {
  width: 100%;
  background-image: url(../../assets/images/storebackground.png);
  background-size: 100% auto;
  margin-top: -62px;
}
.genAIChatIcon {
  background: url("../../assets/images/Chat-logo.svg") no-repeat;
  width: 129px;
  height: 106px;
  position: absolute;
  bottom: 39px;
  right: 53px;
  display: inline-flex;
  align-items: center;
}
.chatHeaderIcon {
  background: url("../../assets/images/chat-logo-medium.svg") no-repeat;
  width: 78px;
  height: 64px;
  zoom: 0.8;
  display: inline-flex;
  align-items: center;
  float: left;
  margin: 2px;
}
.chatCloseButton {
  background: url("../../assets/images/sprite.svg") no-repeat -220px -59px;
  width: 31px;
  height: 31px;
  display: inline-flex;
  align-items: center;
  float: right;
  margin-top: 13px;
  margin-right: 17px;
}
.chatHeaderTitle {
  color: #000;
  text-align: center;
  font-family: HM Sans;
  font-size: 21px;
  font-style: normal;
  font-weight: 300;
  line-height: 56px;
  padding-left: 13px;
}
.header {
  background-color: #ffc700;
  height: 59px;
}
.chatModel {
  width: 70%;
  right: 0;
}
.scrollableContent-99 {
  width: 100% !important;
}
