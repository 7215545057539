.questionInputContainer {
  height: 71px;
  width: 100%;
  left: 2.5%;
  right: 0%;
  top: 0%;
  bottom: 0%;
  background: #ffffff;
  border-radius: 14px;
  box-shadow: 4px 4px 4px 0px rgb(0 0 0 / 25%);
}
.audioInput {
  margin-top: 22px;
  margin-right: 9px;
}
.questionInputTextArea {
  width: 100%;
  line-height: 40px;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 58px;
  margin-right: 3px;
}
.fieldGroup-145 {
  width: 93% !important;
}

.questionInputSendButtonContainer {
  /* position: absolute;
  right: -38px;
  bottom: 27px; */
}

.questionInputSendButton {
  /* width: 24px;
  height: 23px; */
  background: url("../../assets/images/sprite.svg") no-repeat -29px -238px;
  width: 54px;
  height: 54px;
  display: inline-flex;
  align-items: center;
  margin-right: 25px;
  margin-bottom: -51px;
  zoom: 0.8;
}

.questionInputSendButtonDisabled {
  /* opacity: 0.4; */
  /* width: 24px;
  height: 23px; */
  background: none;
  color: #424242;
  background: url("../../assets/images/sprite.svg") no-repeat -29px -159px;
  width: 54px;
  height: 54px;
  display: inline-flex;
  align-items: center;
  margin-right: 25px;
  margin-bottom: -51px;
  zoom: 0.8;
  opacity: 0.6; /* Example: reduce opacity for a faded look */
  cursor: not-allowed;
}

.questionInputBottomBorder {
  position: absolute;
  width: 100%;
  height: 4px;
  left: 0%;
  bottom: 0%;
  background: radial-gradient(
    106.04% 106.06% at 100.1% 90.19%,
    #0f6cbd 33.63%,
    #8dddd8 100%
  );
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.questionInputOptionsButton {
  cursor: pointer;
  width: 27px;
  height: 30px;
}
.soundwave {
  width: 100px;
  height: 50px;
  position: absolute;
  top: 69%;
  left: 50%;
  z-index: 100;
  transform: translate(-50%, -50%);
}
.soundwaveright {
  width: 100px;
  height: 50px;
  position: absolute;
  top: 69%;
  left: 58%;
  z-index: 100;
  transform: translate(-50%, -50%);
}

.micOffSpriteIcon {
  background: url("../../assets/images/sprite.svg") no-repeat -114px -159px;
  width: 76px;
  height: 55px;
  display: inline-flex;
  align-items: center;
  margin-top: 17px;
  margin-right: 7px;
  zoom: 0.8;
}
.micOffSpriteIconDisabled {
  background: url("../../assets/images/sprite.svg") no-repeat -114px -159px;
  width: 76px;
  height: 55px;
  display: inline-flex;
  align-items: center;
  margin-top: 17px;
  margin-right: 7px;
  zoom: 0.8;
  opacity: 0.6; /* Example: reduce opacity for a faded look */
  cursor: not-allowed;
}
.micOnSpriteIcon {
  background: url("../../assets/images/sprite.svg") no-repeat -114px -238px;
  width: 76px;
  height: 55px;
  display: inline-flex;
  align-items: center;
  margin-top: 17px;
  margin-right: 7px;
  zoom: 0.8;
}
