@font-face {
  font-family: 'HMSansBold';
  src: url('../fonts/HMSansBold.otf');
}

@font-face {
  font-family: 'HMSansRegular';
  src: url('../fonts/HMSansRegular.otf');
}

@font-face {
  font-family: 'HMSansSemiBold';
  src: url('../fonts/HMSansSemiBold.otf');
}

@font-face {
  font-family: 'HMSansLight';
  src: url('../fonts/HMSansLight.otf');
}

// If you want to override variables do it here
@import 'variables';

// Import CoreUI for React components library
@import '@coreui/coreui/scss/coreui';

// Import Chart.js custom tooltips styles

@import '@coreui/chartjs/scss/coreui-chartjs';

@import 'layout';
@import 'example';

@import 'header';
@import 'sidebar';
// @import 'peakSeason';
// If you want to add custom CSS you can put it here.
@import 'custom';

html body {
  height: 100%;
  font-family: 'HMSansRegular', sans-serif;
  scroll-behavior: smooth;
}
