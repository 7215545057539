.container {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 20px;
  min-height: 86vh;
  /* background-color: #c2c2a3; */
  /* border-radius: 23px; */
  /* background: linear-gradient(
    180deg,
    #79abc2 0%,
    #6f9dbe 20.31%,
    #4e6fa5 40.63%,
    #354c86 58.85%,
    #263463 79.17%,
    #202450 100%
  ); */
  /* background-image: url(../../assets/images/chatbackgroundold.png); */
  /* background-size: cover; */
  fill: #fafafa;
  stroke-width: 1px;
  stroke: #cecece;
  filter: drop-shadow(9px 2px 5px rgba(0, 0, 0, 0.33));
}
.emailDialog {
  width: 100%;
  padding: 10px;
}
.chatcontainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 3px;
}
.chatbackgroundimage {
  width: 44vh;
  height: 35vh; /* Set the height of the container */
  background-size: cover; /* Adjust the background size as needed */
  background-repeat: no-repeat;
  background-image: url(../../assets/images/ChatBackgroundImg.svg);
}
.chatRoot {
  flex: 1;
  display: flex;
  margin-top: 0px;
  margin-bottom: -26px;
  margin-left: 20px;
  margin-right: 23px;
  gap: 20px;
}

.chatContainer {
  height: 83vh;
  margin-top: 10px;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* background: rgba(230, 230, 210, 0.1); */
  /* box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14), 0px 0px 2px rgba(0, 0, 0, 0.12);
  border-radius: 8px; */
  overflow-y: auto;
  max-height: calc(100vh - 85px);
  /* backdrop-filter: blur(7px);
  border: 1px solid rgba(255, 255, 255, 0.25);
  border-radius: 20px;
  background-color: rgba(255, 255, 255, 0.45);
  box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.25); */
}

.chatEmptyState {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
  margin-top: 0px;
}

.chatEmptyStateTitle {
  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  display: flex;
  align-items: flex-end;
  text-align: center;
  line-height: 24px;
  margin-top: 36px;
  margin-bottom: 0px;
}

.chatEmptyStateSubtitle {
  margin-top: 20px;
  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  display: flex;
  align-items: flex-end;
  text-align: center;
  letter-spacing: -0.01em;
}

.chatIcon {
  height: 62px;
  /* width: 62px; */
}

.chatMessageStream {
  flex-grow: 1;
  max-width: 1028px;
  width: 100%;
  overflow-y: auto;
  padding-left: 24px;
  padding-right: 24px;
  display: flex;
  flex-direction: column;
  margin-top: 24px;
}

.chatMessageUser {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 12px;
}

.chatMessageUserMessage {
  padding: 20px;
  /* background: #edf5fd;
  border-radius: 8px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14), 0px 0px 2px rgba(0, 0, 0, 0.12); */
  /* border-radius: 27px; */
  /* border-radius: 5.419px;
  background: linear-gradient(
    180deg,
    #79abc2 0%,
    #5788ac 49.48%,
    #5070a4 98.44%
  );
  box-shadow: 1px 1px 6px 0px rgba(0, 0, 0, 0.65);
  color: white !important; */
  border-radius: 12px;
  background: #eef3f5;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  color: #000;
  font-family: HM Sans;
  font-size: 13px;
  font-style: normal;
  font-weight: 300;
  line-height: 15px;
  line-height: 22px;
  flex: none;
  order: 0;
  flex-grow: 0;
  white-space: pre-wrap;
  word-wrap: break-word;
  max-width: 800px;
}

.chatMessageGpt {
  margin-bottom: 12px;
  max-width: 80%;
  display: flex;
}

.chatInput {
  position: sticky;
  flex: 0 0 100px;
  padding-top: 12px;
  padding-bottom: 2px;
  /* padding-left: 24px;
  padding-right: 24px; */
  width: 98%;
  /* max-width: 1028px; */
  /* margin-bottom: 50px; */
  margin-top: 8px;
}

.clearChatBroom {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px;
  position: absolute;
  width: 40px;
  height: 40px;
  left: -45px;
  top: 61px;
  /* border: 1px solid #d1d1d1; */
  border-radius: 20px;
  background: linear-gradient(
    180deg,
    #ffc700 0%,
    #ffc700 49.48%,
    #ffc700 98.44%
  ) !important;
  /* box-shadow: 1px 1px 6px 0px rgb(0 0 0 / 65%); */
  z-index: 1;
}
.AnswerTexButton {
  border-radius: 4px;
  background: var(--primary-primary-100, #222);
  color: white;
}
.gridcontainer {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Two columns with equal width */
  gap: 16px; /* Gap between grid items */
  padding-left: 13px;
  padding-right: 13px;
  width: 100%;
}

.griditem {
  width: 100%;
  border-radius: 8px;
  border: 1px solid #ddd;
  background: #fbfbfb;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15);
  padding: 16px;
}
.stopGeneratingContainer {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 5px 16px;
  gap: 4px;
  position: absolute;
  width: 161px;
  height: 32px;
  left: calc(50% - 161px / 2 + 25.8px);
  bottom: 116px;
  border: 1px solid #d1d1d1;
  border-radius: 16px;
}

.stopGeneratingIcon {
  width: 14px;
  height: 14px;
  color: #424242;
}

.stopGeneratingText {
  width: 103px;
  height: 20px;
  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #242424;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.citationPanel {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px 16px;
  gap: 8px;
  background: rgb(255 255 255 / 80%);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14), 0px 0px 2px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  flex: auto;
  order: 0;
  align-self: stretch;
  flex-grow: 0.3;
  max-width: 30%;
  overflow-y: scroll;
  max-height: calc(100vh - 100px);
}

.citationPanelHeaderContainer {
  width: 100%;
}

.citationPanelHeader {
  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #000000;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.citationPanelDismiss {
  width: 18px;
  height: 18px;
  color: #424242;
}

.citationPanelDismiss:hover {
  background-color: #d1d1d1;
  cursor: pointer;
}

.citationPanelTitle {
  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #323130;
  margin-top: 12px;
  margin-bottom: 12px;
}

.citationPanelContent {
  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
}

a {
  padding-left: 5px;
  padding-right: 5px;
}

.tab {
  overflow: hidden;
}

.tab button {
  position: relative;
  background-color: inherit;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 30px;
  padding: 14px 16px;
  transition: 0.3s;
  font-size: 17px;
  border: 1px solid #cccc;
  color: #676767;
  z-index: 1;
  width: 50%;
}
.tab button.active {
  z-index: 2;
  color: #ffffff;
  background-color: #009bdf;
}
.clearChatBroomSpriteIcon {
  background: url("../../assets/images/sprite.svg") no-repeat -197px -238px;
  width: 52px;
  height: 52px;
  display: inline-flex;
  align-items: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px;
  position: absolute;
  left: 18px;
  top: 80px;
  /* border: 1px solid #d1d1d1; */
  z-index: 1;
  zoom: 0.8;
}

.chatContainerActionTitle {
  display: flex;
  justify-content: space-between;
  align-items: end;
  width: 100%;
}

.left {
  flex: 1;
}
.indexDropDown {
  width: 33%;
  float: right;
  margin-right: -58px;
}
.right {
  flex: 1;
  display: flex;
  justify-content: flex-end;
}
.pill-115 {
  background: #000 !important;
  border-color: transparent;
}
