.sidebar {
    height: 100%;
}
.sidebar-brand {
    background-color: #0d1927;
    overflow-x: hidden;
}
.sidebar-toggler {
    background-color: #0d1927 !important;
    width: auto !important;
}
.nav-icon {
    height: 35px !important;
    width: 35px !important;
}
.sidebar-nav .nav-link {
    padding-left: 20px !important;
}
.sidebar-nav .nav-link,
.sidebar-nav svg.nav-icon {
    color: rgba(255, 255, 255, 0.4);
}
.sidebar-nav .nav-link.active {
    background-color: #070d15 !important;
    color: #ffffff !important;
}
.sidebar-nav {
    line-height: 50px;
    font-size: 16px;
}
.sidebar-nav .nav-link.active .nav-icon {
    color: #ffffff;
}

.sidebar-nav .nav-link .nav-icon:hover {
    color: #ffffff;
}
.sidebar-logo {
    height: 50px;
    width: 50px;
}
.sidebar-icon {
  height: 27px !important;
    width: 100% !important;
    margin: 0px !important;
}

.sidebar-nav .nav-link:hover {
    color: #ffffff !important;
    background-color: #0d1927;
}

.bt-health-icon {
    background: url("../images/svgs/spriteSVGs.svg");
    display: inline-flex;
    vertical-align: middle;
    background-position: 2822px -75px;
}

.sidebar-nav .nav-link.active .bt-health-icon,
.sidebar-nav .nav-link:hover .bt-health-icon {
    // background: url('../brand/btHealthWhite.png') no-repeat;

    background: url("../images/svgs/spriteSVGs.svg");
    display: inline-flex;
    vertical-align: middle;
    background-position: 774px -10px;
}
