.layout {
  display: flex;
  /* flex-direction: column; */
  height: 90vh;
  margin-top: -6px;
}
@media screen and (min-width: 4101px) and (max-width: 5500px) {
  .layout {
    height: 98vh;
  }
}

@media screen and (min-width: 2801px) and (max-width: 4100px) {
  .layout {
    height: 97vh;
  }
}
@media screen and (min-width: 2101px) and (max-width: 2800px) {
  .layout {
    height: 95vh;
  }
}
@media screen and (min-width: 1851px) and (max-width: 2100px) {
  .layout {
    height: 93.4vh;
  }
}
@media screen and (min-width: 1701px) and (max-width: 1850px) {
  .layout {
    height: 92.5vh;
  }
}
@media screen and (min-width: 1601px) and (max-width: 1700px) {
  .layout {
    height: 92vh;
  }
}
@media screen and (min-width: 1500px) and (max-width: 1600px) {
  .layout {
    height: 91vh;
  }
}
@media screen and (min-width: 1200px) and (max-width: 1500px) {
  .layout {
    height: 90vh;
  }
}
@media screen and (min-width: 1000px) and (max-width: 1199px) {
  .layout {
    height: 90vh;
  }
}
@media screen and (min-width: 900px) and (max-width: 999px) {
  .layout {
    height: 90vh;
  }
}
@media screen and (min-width: 700px) and (max-width: 899px) {
  .layout {
    height: 90vh;
  }
}
@media screen and (min-width: 600px) and (max-width: 699px) {
  .layout {
    height: 90vh;
  }
}
@media screen and (min-width: 400px) and (max-width: 599px) {
  .layout {
    zoom: 0.3;
  }
}
@media screen and (min-width: 300px) and (max-width: 399px) {
  .layout {
    zoom: 0.2;
  }
}
@media screen and (max-width: 299px) {
  .layout {
    zoom: 0.2;
  }
}

.header {
  background-color: #f2f2f2;
}

.headerContainer {
  display: flex;
  justify-content: left;
  align-items: center;
}

.headerTitleContainer {
  display: flex;
  align-items: center;
  margin-left: 14px;
  text-decoration: none;
}

.headerTitle {
  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  display: flex;
  align-items: flex-end;
  color: #242424;
}

.headerIcon {
  height: 32px;
  width: 32px;
  margin-left: 36px;
}

.shareButtonContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 5px 12px;
  gap: 4px;
  width: 86px;
  height: 32px;
  background: radial-gradient(
      109.81% 107.82% at 100.1% 90.19%,
      #0f6cbd 33.63%,
      #2d87c3 70.31%,
      #8dddd8 100%
    )
    /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
  border-radius: 4px;
  flex: none;
  order: 1;
  flex-grow: 0;
  position: absolute;
  right: 20px;
  cursor: pointer;
}

.shareButton {
  color: #ffffff;
}

.shareButtonText {
  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #ffffff;
}

.urlTextBox {
  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #707070;
  border: 1px solid #d1d1d1;
  border-radius: 4px;
}

.copyButtonContainer {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 5px 12px;
  gap: 4px;
  background: #ffffff;
  border: 1px solid #d1d1d1;
  border-radius: 4px;
}

.copyButtonContainer:hover {
  cursor: pointer;
  background: #d1d1d1;
}

.copyButton {
  color: #424242;
}

.copyButtonText {
  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #242424;
}
.verticalcenter {
  display: flex;
  justify-content: center; /* Horizontally centers the content */
  align-items: center; /* Vertically centers the content */
  height: 75vh; /* Ensures it takes the full viewport height, adjust if needed */
  /* Add other styles as needed */
}
